<template>
  <validation-observer
    ref="observer"
    v-slot="{ pristine, invalid, handleSubmit }"
  >
    <b-modal
      id="modal-add-user-match-pool"
      v-model="showModal"
      size="lg"
      :title="`Add ${addingRole} for ${user.full_name}`"
      centered
      cancel-variant="outline-secondary"
      :ok-disabled="pristine || invalid"
      @hidden="onHidden"
      @ok.prevent="handleSubmit(onSubmit)"
    >
      <b-form>
        <b-row>
          <b-col sm="12">
            <b-form-group
              label="Search"
              label-for="Search"
            >
              <b-input-group
                class="mb-2"
              >
                <b-input-group-prepend class="search-icon">
                  <feather-icon
                    icon="SearchIcon"
                    size="14"
                  />
                </b-input-group-prepend>
                <b-form-input
                  id="`type-search`"
                  v-model="search"
                  type="search"
                  placeholder="Search"
                  class="pool-search"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            v-if="isLoadingMatches"
            sm="12"
          >
            <aom-skeleton-loader    
              :loader-type="loaderTypes.SHORT"
            />
          </b-col>
          <b-col
            v-else
            md="12"
          >
            <b-form-group
              v-slot="{ ariaDescribedby }"
            >
              <validation-provider
                ref="matched-user"
                rules="required"
                vid="matched-user"
                name="Matched User"
              >
                <section
                  v-if="defaultProgram.partner_pref_active"
                  class="border-wrapper"
                >
                  <div>
                    <h5 clas="text-capitalize">
                      Preferred
                    </h5>
                    <p>Preferred {{ addingRole }}s</p>
                  </div>
                  <div
                    v-if="preferredUsers.length === 0"
                    class="text-center"
                  >
                    No Preferred matches found
                  </div>
                  <b-form-radio-group
                    v-for="match in preferredUsers"
                    id="matched-users"
                    :key="match.id"
                    v-model="selectedMatch"
                    :aria-describedby="ariaDescribedby"
                    name="matched-users"
                    class="mb-1"
                  >
                    <b-form-radio
                      :value="match.id"
                      class="grid-item-1"
                    >
                      {{ match.full_name }}
                    </b-form-radio>
                    <apexchart
                      class="grid-item-2"
                      type="radialBar"
                      height="100"
                      :options="options"
                      :series="[match.compatibility]"
                    />
                    <b-button
                      v-b-toggle="`collapse-${match.id}`"
                      variant="outline-primary"
                      class="btn-icon grid-item-3"
                      @click.once="fetchMatchQuestions(match.id)"
                    >
                      <feather-icon
                        icon="ChevronDownIcon"
                        size="16"
                      />
                    </b-button>
                    <b-collapse
                      :id="`collapse-${match.id}`"
                      class="mt-2 grid-item-4 collapse-panel"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card class="text-center">
                        <b-spinner
                          v-if="isLoadingQuestions"
                          variant="primary"
                          class="mt-3 mb-3"
                        />
                        <p
                          v-if="isLoadingQuestions"
                          class="text-center text-muted"
                        >
                          Loading Applications ...
                        </p>
                        <section v-if="(!isLoadingQuestions && menteeUser && menteeUser.user)">
                          <b-row>
                            <b-col
                              sm="6"
                            >
                              <b-row>
                                <b-col sm="9">
                                  <span class="mb-0 d-flex justify-content-end">{{ menteeUser.user.full_name }}</span>
                                  <span class="mb-0 d-flex justify-content-end"><small class="text-muted"> {{ menteeUser.user.email }}</small></span>
                                </b-col>
                                <b-col
                                  sm="3"
                                  class="d-flex justify-content-end"
                                >
                                  <b-avatar
                                    variant="primary"
                                    :text="userInitials(menteeUser.user)"
                                    :src="userProfileImage(menteeUser.user)" 
                                    size="4rem"
                                  />
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col
                              sm="6"
                            >
                              <b-row>
                                <b-col sm="3">
                                  <b-avatar
                                    variant="primary"
                                    :text="userInitials(mentorUser.user)"
                                    :src="userProfileImage(mentorUser.user)" 
                                    size="4rem"
                                  />
                                </b-col>
                                <b-col sm="9">
                                  <span class="mb-0 d-flex justify-content-start">{{ mentorUser.user.full_name }}</span>
                                  <span class="mb-0 d-flex justify-content-start"><small class="text-muted"> {{ mentorUser.user.email }}</small></span>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col sm="6">
                              <aom-languages align="right">
                                <template slot-scope="props">
                                  <application-summary
                                    :user="menteeUser"
                                    :index="props.index"
                                    :locale-id="props.localeId"
                                    :questions="menteeQuestions"
                                    :matched-users-questions="mentorQuestions"
                                  />
                                </template>
                              </aom-languages>
                            </b-col>
                            <b-col sm="6">
                              <aom-languages>
                                <template slot-scope="props">
                                  <application-summary
                                    :user="mentorUser"
                                    :index="props.index"
                                    :locale-id="props.localeId"
                                    :questions="mentorQuestions"
                                    :matched-users-questions="menteeQuestions"
                                  />
                                </template>
                              </aom-languages>
                            </b-col>
                          </b-row>
                        </section>
                      </b-card>
                    </b-collapse>
                  </b-form-radio-group>
                </section>
                <section
                  class="border-wrapper mt-2"
                >
                  <div>
                    <h5 class="text-capitalize">
                      Compatibility
                    </h5>
                    <p>Recommended {{ addingRole }}s</p>
                  </div>
                  <div
                    v-if="compatUsers.length === 0"
                    class="text-center"
                  >
                    No Recommended matches found
                  </div>
                  <b-form-radio-group
                    v-for="match in compatUsers"
                    id="matched-users"
                    :key="match.id"
                    v-model="selectedMatch"
                    :aria-describedby="ariaDescribedby"
                    name="matched-users"
                    class="mb-1"
                  >
                    <b-form-radio
                      :value="match.id"
                      class="grid-item-1"
                    >
                      {{ match.full_name }}
                    </b-form-radio>
                    <apexchart
                      class="grid-item-2"
                      type="radialBar"
                      height="100"
                      :options="options"
                      :series="[match.compatibility]"
                    />
                    <b-button
                      v-b-toggle="`collapse-${match.id}`"
                      variant="outline-primary"
                      class="btn-icon grid-item-3"
                      @click.once="fetchMatchQuestions(match.id)"
                    >
                      <feather-icon
                        icon="ChevronDownIcon"
                        size="16"
                      />
                    </b-button>
                    <b-collapse
                      :id="`collapse-${match.id}`"
                      class="mt-2 grid-item-4 collapse-panel"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card class="text-center">
                        <b-spinner
                          v-if="isLoadingQuestions"
                          variant="primary"
                          class="mt-3 mb-3"
                        />
                        <p
                          v-if="isLoadingQuestions"
                          class="text-center text-muted"
                        >
                          Loading Applications ...
                        </p>
                        <section v-if="(!isLoadingQuestions && menteeUser && menteeUser.user)">
                          <b-row>
                            <b-col
                              sm="6"
                            >
                              <b-row>
                                <b-col sm="9">
                                  <span class="mb-0 d-flex justify-content-end">{{ menteeUser.user.full_name }}</span>
                                  <span class="mb-0 d-flex justify-content-end"><small class="text-muted"> {{ menteeUser.user.email }}</small></span>
                                </b-col>
                                <b-col
                                  sm="3"
                                  class="d-flex justify-content-end"
                                >
                                  <b-avatar
                                    variant="primary"
                                    :text="userInitials(menteeUser.user)"
                                    :src="userProfileImage(menteeUser.user)" 
                                    size="4rem"
                                  />
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col
                              sm="6"
                            >
                              <b-row>
                                <b-col sm="3">
                                  <b-avatar
                                    variant="primary"
                                    :text="userInitials(mentorUser.user)"
                                    :src="userProfileImage(mentorUser.user)" 
                                    size="4rem"
                                  />
                                </b-col>
                                <b-col sm="9">
                                  <span class="mb-0 d-flex justify-content-start">{{ mentorUser.user.full_name }}</span>
                                  <span class="mb-0 d-flex justify-content-start"><small class="text-muted"> {{ mentorUser.user.email }}</small></span>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col sm="6">
                              <aom-languages align="right">
                                <template slot-scope="props">
                                  <application-summary
                                    :user="menteeUser"
                                    :index="props.index"
                                    :locale-id="props.localeId"
                                    :questions="menteeQuestions"
                                    :matched-users-questions="mentorQuestions"
                                  />
                                </template>
                              </aom-languages>
                            </b-col>
                            <b-col sm="6">
                              <aom-languages>
                                <template slot-scope="props">
                                  <application-summary
                                    :user="mentorUser"
                                    :index="props.index"
                                    :locale-id="props.localeId"
                                    :questions="mentorQuestions"
                                    :matched-users-questions="menteeQuestions"
                                  />
                                </template>
                              </aom-languages>
                            </b-col>
                          </b-row>
                        </section>
                      </b-card>
                    </b-collapse>
                  </b-form-radio-group>
                </section>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer="{ ok, cancel}">
        <b-button
          size="md"
          variant="outline-secondary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          size="md"
          variant="primary"
          :disabled="pristine || invalid"
          @click="ok(onSubmit)"
        >
          <b-spinner
            v-if="isUpdating" 
            small
          />
          <span
            v-if="isUpdating"
          > Updating...</span>
          <span v-else>Confirm and Lock</span>
        </b-button>
      </template>
    </b-modal>
  </validation-observer>
</template>
    
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormRadioGroup,
  BForm,
  BModal,
  BFormRadio,
  BCollapse,
  BButton,
  BSpinner,
  BAvatar,
  VBToggle,
  BInputGroupPrepend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
//eslint-disable-next-line
import { required, max } from "@validations";
import matchesService from "@/services/matchesService";
import {
  getValidationState
} from "@/libs/utils";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { mapGetters } from 'vuex';
import { loaderTypes, ProgramMatches, matchStatus, userRoles, userRolesDisplay } from '@models';
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import AomLanguages from "@aom-core/AomLanguages";
import { questionTypes } from '@models/questionTypes';
import ApplicationSummary from "@/views/components/forms/applications/ApplicationSummary.vue";
import _debounce from "lodash/debounce";
  
export default {
  name: "AddUserToPartnerPrefPool",
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BCollapse,
    BForm,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BButton,
    AomSkeletonLoader,
    BSpinner, 
    BAvatar,
    AomLanguages,
    ApplicationSummary,
    BInputGroup,
    BInputGroupPrepend,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {
    user: {
      type: Object,
      default:() => {},
      required: true
    },
    role: {
      type: Number,
      validator (value) {
        return Object.values(userRoles).indexOf(value) !== -1;
      },
      required: true
    },
    currentUserRole: {
      type: Number,
      validator (value) {
        return Object.values(userRoles).indexOf(value) !== -1;
      },
      required: true
    },
    match: {
      type: Object,
      default:() => {},
      required: true
    },
  },
  data() {
    return {
      showModal: false,
      isLoadingMatches: false,
      preferredUsers: [],
      compatUsers: [],
      menteeUser: {},
      menteeQuestions: [],
      mentorQuestions: [],
      mentorUser: {},
      selectedMatch: undefined,
      isLoadingQuestions: false,
      isUpdating: false,
      search: '',
      options: {
        chart: {
          height: 15,
          width: 40,
          type: 'radialBar',
        },
        grid: {
          padding: {
            top: -15,
            bottom: -15
          }
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                offsetX: 1,
                color: "#111",
                fontSize: "14px",
                show: true
              }
            }
          }
        },
      },
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId', 'defaultProgram']),
    addingRole () {
      return this.role === userRoles.MENTEE ? userRolesDisplay[userRoles.MENTEE] : userRolesDisplay[userRoles.MENTOR];
    }
  },
  watch: {
    user(n) {
      if(n && n.id) {
        this.loadMatches();
      }
    },
    search: _debounce(function(newVal){
      this.loadMatches(newVal);
    }, 1000)
  },
  methods: {
    show() {
      this.showModal = true;
    },
    onHidden() {
      this.clearForm();
    },
    clearForm() {
      this.showModal = false;
      this.isLoadingMatches = false;
      this.preferredUsers = [];
      this.menteeUser = {};
      this.mentorUser = {};
      this.selectedMatch = undefined;
      this.isLoadingQuestions = false;
      this.isUpdating = false;
      this.search = '';
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
      this.$emit('clear');
    },
    userInitials(user) {
      if(user.first_name && user.last_name) {
        const firstInt = Array.from(user.first_name)[0];
        const lastInt = Array.from(user.last_name)[0];
        return `${firstInt}${lastInt}`;
      }
    },
    async loadMatches(search) {
      const filter = {
        columnFilters: [],
      };
      if(search) {
        filter.columnFilters.push({field: "keyword",value: search});
      }  
      if(this.user === undefined || this.user.id === undefined) {
        return;
      }
      try {
        const programId = this.defaultProgramId;
        const poolId = this.$route.params.poolId;
        const userId = this.user.id;
        const role = this.role;
        this.isLoadingMatches = true;
        const response = await matchesService.listPoolMatchCandidatesForUser(programId, poolId, userId, role, filter);
        const { data } = response;
        this.preferredUsers = data.items.filter(u => u.is_preferred);
        this.compatUsers = data.items.filter(u => !u.is_preferred);
      } catch (e) {
        console.log(e);
        this.$log.error(e);
        this.$toast(makeErrorToast('Error. Fetching candidates list'));
      }finally {
        this.isLoadingMatches = false;
      }
    },
    async fetchMatchQuestions(id) {
      const matchUsers = [
        // Matched User
        {
          user_id: id,
          role_id: this.role
        },
        // Current User
        {
          user_id: this.user.id,
          role_id: this.currentUserRole
        },
      ];
      const programId = this.defaultProgramId;
      try {
        this.isLoadingQuestions = true;
        const response = await matchesService.getCompareUsers(programId, matchUsers);
        const { data } = response;
        this.menteeUser = data.match_users.filter(m => m.role_id === userRoles.MENTEE).shift();
        this.mentorUser = data.match_users.filter(m => m.role_id === userRoles.MENTOR).shift();
        this.menteeQuestions = this.menteeUser?.user.user_forms[0]?.form?.questions;
        this.mentorQuestions = this.mentorUser?.user.user_forms[0]?.form?.questions;
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast('Error. Fetching application questions'));
      } finally {
        this.isLoadingQuestions = false;
      }
    },
    userProfileImage (user) {
      return user?.profile_image?.url || '';
    },
    onSubmit() {
      this.$bvModal
        .msgBoxConfirm('This new match will be locked in the pool. This action may cause any unlocked matches to be reshuffled.?', {
          title: 'Confirm Locked Match',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Confirm',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            const match = new ProgramMatches({
              status_id: matchStatus.LOCKED,
              match_users: [
                {user_id: this.user.id, role_id: this.currentUserRole}, 
                {user_id: this.selectedMatch, role_id: this.role}
              ]
            });
            const matchId = this.match.id;
            try {
              this.isUpdating = true;
              const response = await matchesService.patchUpdateMatch(this.defaultProgramId, matchId,  match);
              this.$toast(makeSuccessToast('Match Locked successfully.'));
              this.showModal = false;
              this.$emit('published', response);
            } catch (e) {
              const {data} = e && e.response;
              if(data.message) {
                return this.$toast(makeErrorToast(data.message));
              }
              this.$toast(makeErrorToast(e));
              this.$log.error(e);
            } finally {
              this.isUpdating = false;
            }
          }
        });   
    }
  },
  setup() {
    return {
      getValidationState,
      userRoles,
      loaderTypes,
      questionTypes
    };
  },
};
</script>
  <style lang="scss" scoped>
  #matched-users {
      display: grid;
      grid-template-columns: 50% 0 50px auto;
      align-items: center;
  }
  .grid-item-1 {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .grid-item-3 {
      justify-self: end;
  }
  .grid-item-4 {
    grid-column: 1 / 5;
  }
  .collapse-panel {
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .search-icon {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    border-right: none;
    margin-right:-5px;
  }
  .pool-search {
    border-left: none;
  }
  .border-wrapper {
    border: 1px solid #ccc;
    padding: 25px;
    border-radius:5px;
  }
  </style>